import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next'; // Hook for React integration

i18next
  .use(HttpApi) // Enables backend loading
  .use(initReactI18next) // Integrates i18next with React
  .init({
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if translations are missing for the selected language
    debug: true, // Useful for debugging during development

    backend: {
      // Path to the translation files
      loadPath: '/locales/{{lng}}/translation.json', // The JSON file pattern to load based on language
    },

    interpolation: {
      escapeValue: false, // React already escapes values
    },

    react: {
      useSuspense: false, // Disable suspense (optional, if you need to handle loading states differently)
    },
  });

export default i18next;
