import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const languages = [
  { code: 'en', label: 'English 🇬🇧' },
  { code: 'it', label: 'Italiano 🇮🇹' },
  // Add more languages if needed
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language.code)
    setSelectedLanguage(language);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className="flex items-center w-[120px] whitespace-nowrap p-2 bg-gray-200 dark:bg-gray-800 rounded-full shadow-md focus:outline-none transition-all"
      >
        <span className={`text-lg text-gray-800 dark:text-white`}>{selectedLanguage.label}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`text-gray-800 dark:text-white w-5 h-5 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Curtain dropdown */}
      <div
        className={`absolute right-0 z-10 mt-2 w-48 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${isOpen ? 'max-h-56 overflow-y-auto opacity-100' : 'hidden max-h-0 opacity-0'} transition-all duration-300 ease-in-out`}
        style={{ transformOrigin: 'top' }}
      >
        <ul className="py-1 text-sm text-gray-700">
          {languages.map((language) => (
            <li
              key={language.code}
              onClick={() => handleLanguageSelect(language)}
              className="cursor-pointer hover:bg-gray-200 px-4 py-2"
            >
              {language.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSelector;
