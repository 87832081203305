import React from 'react';
import { saveAs } from 'file-saver';  // File-saver for static file download

const DownloadPDFButton = ({fileName, caption}) => {
  const downloadPDF = (fileName, caption) => {
    saveAs('/'+fileName, caption);
  };

  return (
    <button
    onClick={() => downloadPDF(fileName, caption)}  // Call with params
      className="flex items-center justify-center p-3 bg-red-600 text-white rounded-full shadow-lg hover:bg-red-700 transition duration-200 ease-in-out"
      aria-label="Download PDF"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-file-earmark-pdf"
        viewBox="0 0 16 16"
        aria-hidden="true"
      >
        <path d="M5.5 0A1.5 1.5 0 0 1 7 1.5V14h1V1.5A2.5 2.5 0 0 0 5.5 0zM1 2h3v12H1V2zm12 0h3v12h-3V2z"/>
      </svg>
      <span className='px-4'>{caption}</span>
    </button>
  );
};

export default DownloadPDFButton;
