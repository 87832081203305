import React, { useState, useEffect, useRef } from 'react';

const LandingImage = ({ imageUrl, altText }) => {
  const [isInView, setIsInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);  // Track if the animation has been triggered
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasAnimated) {
          setIsInView(true);
          setHasAnimated(true); // Set animation as triggered
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [hasAnimated]); // Depend on hasAnimated so it doesn't re-trigger once it's true

  return (
    <>
      <div
        ref={elementRef}
        className={`${isInView ? 'opacity-100 slide-in-left' : 'opacity-0'}`}
      >
        <img
          src={imageUrl}
          alt={altText}
          className="aspect-[4/3] w-full sm:w-[84vw] md:w-[36vw] lg:w-[35vw] xl:w-[36vw] 2xl:w-[700px]
    max-w-full h-auto shadow-lg object-cover block"
        />
      </div>
    </>
  );
};

export default LandingImage;
