'use client'
import LandingImage from './LandingImage';
import Background from './Background';
import { useTranslation, Trans } from 'react-i18next';
import AnimatedComponentText from './AnimatedComponentText';
import {useRef, useState, useEffect} from 'react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";


export default function LandingComponent() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const location = useLocation();

  // Use useLayoutEffect to ensure the scroll happens before the page re-renders
  useLayoutEffect(() => {
    // Wait for the page to fully load and reset scroll
    window.scrollTo(0, 0);
  }, [location]);

  const { t } = useTranslation();
  // Create a reference to the element you want to scroll to
  const scrollToRef = useRef(null);

  const [clicked, setClicked] = useState(false);

  // Function to handle click and scroll to the target element
  const handleClick = () => {
    setClicked(prev => !prev);
    // Scroll the target element into view
    scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });

  };

  return (
    <div>
      <Background/>
      <div className="relative isolate px-8 pt-14 lg:px-28">
        <h1 className="text-balance text-red-700:sm text-5xl font-semibold tracking-tight text-gray-800 sm:text-7xl dark:text-gray-300 slide-in-left">
                GIACOMO CALCARA
        </h1>
        <div className="flex item-start justify-start">
          <p className=" mt-8 text-pretty text-xl font-medium text-gray-700 sm:text-3xl/8 dark:text-gray-300 fade-in" style={{ lineHeight: '1.1'}}>
              <Trans i18nKey="who_i_am" components={{ b: <b />, br: <br /> }}>
              </Trans>
          </p>
          <div className="max-w-2xl py-32 px-2 sm:py-48 lg:py-56">
            {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Announcing our next round of funding.{' '}
                <a href="#" className="font-semibold text-indigo-600">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div> */}
              {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a href="#" className="text-sm/6 font-semibold text-gray-900">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div> */}
          </div>
        </div>
      </div>
      <div class="flex justify-center items-left my-4 lg:m-0">
      <p className="text-pretty text-2xl font-medium text-gray-700 sm:text-4xl/8 dark:text-gray-300 fade-in">
              <Trans i18nKey="learn_more" components={{ b: <b />, br: <br /> }}>
              </Trans>
            </p>
      </div>

      <div onClick={handleClick} class="flex justify-center items-left py-7 lg:p-0"
       style={{ margin: '5vw'}}>
            <div className={`arrow cursor-pointer ${clicked ? 'clicked' : ''}`} 
        style={{ cursor: 'pointer' }}></div>
      </div>
      <div className='flex flex-col md:flex-row py-16'>
          <LandingImage 
            imageUrl="pc-business.jpg"
            altText="pc-business"
            />
            <div ref={scrollToRef} className="px-8 py-8 self-center">
            <p className="text-pretty text-2xl font-medium text-gray-700 sm:text-4xl/8 dark:text-gray-300 fade-in py-2">
              <Trans i18nKey="sector" components={{ b: <b />, br: <br /> }}>
              </Trans>
            </p>
              <AnimatedComponentText text="experience_1"/>
              <div className="mt-16">
                <Link className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                focus-visible:outline-indigo-600 text-gray-300" to="/about">{t('about_me')}</Link>
               </div>
            </div>
      </div>
    </div>
  )
}
