import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

const AnimatedComponentText = ({text}) => {
  const [isInView, setIsInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);  // Track if the animation has been triggered
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasAnimated) {
          setIsInView(true);
          setHasAnimated(true); // Set animation as triggered
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [hasAnimated]); // Depend on hasAnimated so it doesn't re-trigger once it's true

  return (
    <div
      ref={elementRef}
      className={`${
        isInView ? 'opacity-100 fade-in-fast' : 'opacity-0'
      } transition-opacity duration-1000`}
    >
      <h1 className="max-w-2xl mt-8 text-pretty text-xl font-medium text-gray-700 sm:text-2xl/8 dark:text-gray-300">
      <Trans i18nKey={text} components={{ b: <b />, br: <br />, ul: <ul/>, li: <li/> }}>
      </Trans></h1>
    </div>
  );
};

export default AnimatedComponentText;
