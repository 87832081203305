import React from 'react';
import DownloadPDFButton from './DownloadPDFButton';
import { useTranslation, Trans } from 'react-i18next';
import Background from './Background';

const App = () => {
    const { t } = useTranslation();
  return (
    <div>
    <Background/>
    <div className="relative isolate px-8 pt-14 lg:px-28">
        <h1 className="text-balance text-red-700:sm text-5xl font-semibold tracking-tight text-gray-800 sm:text-7xl dark:text-gray-300 slide-in-left">
            {t('contact_me')}
        </h1>
        <h2 className="text-balance text-red-700:sm text-2xl font-semibold tracking-tight text-gray-800 sm:text-4xl dark:text-gray-300 slide-in-left py-8">
        ✉️  Email
        </h2>
        <h2 className="text-balance text-red-700:sm text-2xl font-semibold tracking-tight text-gray-800 sm:text-4xl dark:text-gray-300 slide-in-left py-1 hover:text-blue-500 dark:hover:text-blue-500">
        <a href="mailto:giacomocalcara94@gmail.com">giacomocalcara94@gmail.com</a>
        </h2>
        <h2 className="text-balance text-red-700:sm text-2xl font-semibold tracking-tight text-gray-800 sm:text-4xl dark:text-gray-300 slide-in-left pt-16 pb-8">
        📱Mobile
        </h2>
        <h2 className="text-balance text-red-700:sm text-3xl font-semibold tracking-tight text-gray-800 sm:text-5xl dark:text-gray-300 slide-in-left py-1 hover:text-blue-500 dark:hover:text-blue-500">
        <a href="tel:+393483777661">+393483777661</a>
        </h2>
        <h2 className="text-balance text-red-700:sm text-3xl font-semibold tracking-tight text-gray-800 sm:text-5xl dark:text-gray-300 slide-in-left pt-16 pb-8">
            <div className='flex'>           
        <img src="/LinkedIn_icon.svg.png"
        alt="LinkedIn"
        className="w-[45px]
    max-w-full h-auto  shadow-lg object-cover block mr-2"></img>    <a className='hover:text-blue-500' href="https://www.linkedin.com/in/giacomo-calcara-94a7b8102?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank">LinkedIn</a>
    </div>
        </h2>
        <h2 className="text-balance text-red-700:sm text-3xl font-semibold tracking-tight text-gray-800 sm:text-5xl dark:text-gray-300 slide-in-left pt-16 pb-8">
            <div className='flex'>
    <div className="mr-2">
    <svg height="45" aria-hidden="true" viewBox="0 0 24 24" version="1.1" width="45" data-view-component="true" class="octicon octicon-mark-github v-align-middle color-fg-default">
    <path d="M12.5.75C6.146.75 1 5.896 1 12.25c0 5.089 3.292 9.387 7.863 10.91.575.101.79-.244.79-.546 0-.273-.014-1.178-.014-2.142-2.889.532-3.636-.704-3.866-1.35-.13-.331-.69-1.352-1.18-1.625-.402-.216-.977-.748-.014-.762.906-.014 1.553.834 1.769 1.179 1.035 1.74 2.688 1.25 3.349.948.1-.747.402-1.25.733-1.538-2.559-.287-5.232-1.279-5.232-5.678 0-1.25.445-2.285 1.178-3.09-.115-.288-.517-1.467.115-3.048 0 0 .963-.302 3.163 1.179.92-.259 1.897-.388 2.875-.388.977 0 1.955.13 2.875.388 2.2-1.495 3.162-1.179 3.162-1.179.633 1.581.23 2.76.115 3.048.733.805 1.179 1.825 1.179 3.09 0 4.413-2.688 5.39-5.247 5.678.417.36.776 1.05.776 2.128 0 1.538-.014 2.774-.014 3.162 0 .302.216.662.79.547C20.709 21.637 24 17.324 24 12.25 24 5.896 18.854.75 12.5.75Z"></path>
</svg>
</div>
  <a className='hover:text-blue-500' href="https://github.com/Jack1618" target="_blank">GitHub</a>
    </div>
        </h2>
    </div>
    </div>
  );
};

export default App;
