import React from 'react';
import './App.css';
import LandingComponent  from './components/LandingComponent';
import './fadeAnimation.css'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './components/About';
import Resume from './components/Resume';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactMe from './components/ContactMe';

import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <Router>
    <div className='flex flex-col min-h-screen flex-grow'>
    <Header/> {/* The Header will have navigation, but no Router */}
      <Routes>
        <Route path="/contact-me" element={<ContactMe />} /> 
        <Route path="/resume" element={<Resume />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={<LandingComponent />} />
      </Routes>
    </div>
    <ScrollToTopButton />
    <Footer/>
  </Router>

  );
}

export default App;
