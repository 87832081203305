import React from 'react';
import DownloadPDFButton from './DownloadPDFButton';
import { useTranslation, Trans } from 'react-i18next';
import Background from './Background';

const App = () => {
    const { t } = useTranslation();
  return (
    <div>
    <Background/>
    <div className="relative isolate px-8 pt-14 lg:px-28">
        <h1 className="text-balance text-red-700:sm text-5xl font-semibold tracking-tight text-gray-800 sm:text-7xl dark:text-gray-300 slide-in-left">
            {t('download my resume')}
        </h1>
        <div className='py-8'>
            <DownloadPDFButton fileName={t('my_resume_file')} caption={t('my_resume')}/>
        </div>
    </div>
    </div>
  );
};

export default App;
