import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show the button when scrolling down 300px
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the user back to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
        onClick={scrollToTop}
        className="fixed bottom-10 right-10 w-16 h-16 bg-purple-600 text-white flex items-center justify-center shadow-lg hover:bg-purple-700 transition duration-300"
        aria-label="Back to top"
      >
        {/* This keeps the arrow pointing up */}
        <span className="text-3xl">&uarr;</span>
      </button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
