'use client'
import LandingImage from './LandingImage';
import Background from './Background';
import { useTranslation, Trans } from 'react-i18next';
import AnimatedComponentText from './AnimatedComponentText';
import {useRef, useState} from 'react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";

export default function About() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const location = useLocation();

  // Use useLayoutEffect to ensure the scroll happens before the page re-renders
  useLayoutEffect(() => {
    // Wait for the page to fully load and reset scroll
    window.scrollTo(0, 0);
  }, [location]);
  const { t } = useTranslation();
  // Create a reference to the element you want to scroll to
  const scrollToRef = useRef(null);

  const [clicked, setClicked] = useState(false);

  // Function to handle click and scroll to the target element
  const handleClick = () => {
    setClicked(prev => !prev);
    // Scroll the target element into view
    scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });

  };
  return (
    <div>
      <Background/>
      <div className='flex flex-col md:flex-row py-16'>
          <LandingImage 
            imageUrl="programming.jpg"
            altText="programming"
            />
            <div ref={scrollToRef} className="px-8 py-8 self-center">
            <p className="text-pretty text-2xl font-medium text-gray-700 sm:text-4xl/8 dark:text-gray-300 fade-in py-2">
              <Trans i18nKey="technical skills" components={{ b: <b />, br: <br /> }}>
              </Trans>
            </p>
              <AnimatedComponentText text="technical_skills_detail_1"/>
              <AnimatedComponentText text="technical_skills_detail_2"/>
            </div>
      </div>
      <div className='flex flex-col md:flex-row py-16'>
          <LandingImage 
            imageUrl="cofano.jpeg"
            altText="cofano"
            />
            <div ref={scrollToRef} className="px-8 py-8 self-center">
            <p className="text-pretty text-2xl font-medium text-gray-700 sm:text-4xl/8 dark:text-gray-300 fade-in py-2">
              <Trans i18nKey="education_title" components={{ b: <b />, br: <br /> }}>
              </Trans>
            </p>
              <AnimatedComponentText text="education"/>
              <AnimatedComponentText text="check_resume"/>
              <Link className="max-w-2xl mt-8 text-pretty text-xl font-medium sm:text-2xl/8 text-indigo-600 underline" to="/resume">{t('here')}</Link>
            </div>
      </div>
      <div className='flex flex-col md:flex-row py-16'>
          <LandingImage 
            imageUrl="guitar.jpg"
            altText="guitar"
            />
            <div ref={scrollToRef} className="px-8 py-8 self-center">
            <p className="text-pretty text-2xl font-medium text-gray-700 sm:text-4xl/8 dark:text-gray-300 fade-in py-2">
              <Trans i18nKey="more_than_cs" components={{ b: <b />, br: <br /> }}>
              </Trans>
            </p>
              <AnimatedComponentText text="guitarist"/>
            </div>
      </div>
    </div>
  )
}
