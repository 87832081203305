import React, { useState, useEffect } from 'react';

const ThemeToggle = () => {
  const [theme, setTheme] = useState(() => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    return systemTheme;
  });

  // Apply the theme to the HTML element (for tailwind dark mode class)
  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
    // Save the theme to localStorage so it's persistent
    localStorage.setItem('theme', theme);
  }, [theme]); // Run whenever theme changes

  const toggleTheme = () => {

    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  return (

    <button
    onClick={toggleTheme}
    className="whitespace-nowrap p-2 bg-gray-200 dark:bg-gray-800 rounded-full shadow-md focus:outline-none transition-all"
  >
    <span className={`text-lg text-gray-800 dark:text-white`}>
    {theme === 'dark' ? 'Dark 🌙  ' : 'Light 🌞'}
    </span>
  </button>
  );
};

export default ThemeToggle;
